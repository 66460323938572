<script lang="ts" setup>
import DefaultLayout from '~/layouts/default.vue';

const isAuthenticated = useClientSideOnlyComputed(
  false,
  () => useAuthenticationState().isAuthenticated
);
</script>

<template>
  <DefaultLayout>
    <template #logo>
      <div :class="$style.container">
        <img
          v-if="isAuthenticated"
          :class="$style.logo"
          src="~/assets/images/logo/logo-smcc.svg"
          alt="オカネコ for SMCC"
        />
        <NuxtLink v-else :to="{ path: '/partner/smcc' }">
          <img
            :class="$style.logo"
            src="~/assets/images/logo/logo-smcc.svg"
            alt="オカネコ for SMCC"
          />
        </NuxtLink>
      </div>
    </template>
    <slot />
  </DefaultLayout>
</template>

<style lang="scss" module>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.logo {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  max-width: 360px;
  padding: auto;
}
</style>
